export default {
  apiLoyalty: "https://loyalty.privilegeplusloyaltyrewards.com/api",
  //apiLoyalty: "https://dev-loyalty.privilegeplusloyaltyrewards.com/api",

  loyalty: "https://loyalty.privilegeplusloyaltyrewards.com/api",
  //loyalty: "https://dev-loyalty.privilegeplusloyaltyrewards.com/api",

  //apiLoyalty: "http://192.168.70.132:5002/api",
  //loyalty: "http://192.168.70.132:5002/api",

  api: "https://loyalty.privilegeplusloyaltyrewards.com/api",
  //api: "https://dev-loyalty.privilegeplusloyaltyrewards.com/api",

  //api: "http://192.168.70.132:5002/api",

  merchant: "https://merchant.privilegeplusloyaltyrewards.com/api",
  //merchant: "https://dev-merchant.privilegeplusloyaltyrewards.com/api",

  //merchant: "http://10.192.121.81:5003/api",

  IdentityServer1: "https://identity.privilegeplusloyaltyrewards.com/api",
  //IdentityServer1: "https://dev-identity.privilegeplusloyaltyrewards.com/api",

  //IdentityServer1: "http://192.168.70.132:5032/api",

  IdentityServer: "https://identity.privilegeplusloyaltyrewards.com",
  //IdentityServer: "https://dev-identity.privilegeplusloyaltyrewards.com",

  //IdentityServer: "http://192.168.70.132:5032",

  report: "https://reports.privilegeplusloyaltyrewards.com/api",
  //report: "https://dev-reports.privilegeplusloyaltyrewards.com/api",
  //report: "http://192.168.70.132:5004/api",

  // apiGateway: "http://10.192.121.78:5020",
};
