/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images

import { useEffect, useState } from "react";

import * as extendedFun from "extensions/extendedFunctions";

function hideMiddleCharacters(str) {
  // Check if the string length is less than or equal to 6, in which case, there's nothing to hide
  if (str.length <= 6) {
    return str;
  }

  // Extract the first 3 characters
  let firstThree = str.slice(0, 3);

  // Extract the last 3 characters
  let lastThree = str.slice(-3);

  // Calculate the number of characters to hide
  let middleLength = str.length - 6;

  // Replace middle characters with "*"
  let hiddenMiddle = "*".repeat(middleLength);

  // Concatenate the first three characters, hidden middle, and last three characters
  let result = firstThree + hiddenMiddle + lastThree;

  return result;
}
function getMonthName(dateString) {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Use toLocaleString to get the month name
  const monthName = date.toLocaleString("default", { month: "long" });

  return monthName;
}
export default function data(transactions) {
  console.log("transactions in auth table:" + JSON.stringify(transactions));
  const [happening, setHappening] = useState([]);
  //const [filter, setFilter] = useState(transactionFilter);

  console.log("table data transaction ");
  const Info = ({ data }) => (
    <MDBox display="block" color="text" fontWeight="small">
      <MDTypography display="block" variant="caption" color="black" fontWeight="small">
        {data}
      </MDTypography>
    </MDBox>
  );

  console.log("array++");

  /*const getTransaction = () => {
    const url = `${Api.report}/TransactionReport/TransactionReport`;
    console.log("url" + url);

    axios.post(url, transactionFilter).then((response) => {
      const arr = response.data;
      console.log("array:" + JSON.stringify(arr));
      setHappening(arr);
    });
  };

  useEffect(() => {
    console.log("getTransaction()");

    getTransaction();
  }, [transactionFilter]);*/

  useEffect(() => {
    console.log("getTransaction()");

    setHappening(transactions);
  }, [transactions]);
  return {
    columns: [
      //{ Header: "merchant", accessor: "merchant", align: "center" },
      { Header: "Customer", accessor: "customerName", width: "20%", align: "left" },
      { Header: "PrivilegePLUS ID", accessor: "memberId", width: "20%", align: "center" },

      { Header: "Date", accessor: "date", width: "15%", align: "center" },
      { Header: "Time", accessor: "time", width: "15%", align: "center" },

      { Header: "Month", accessor: "month", width: "15%", align: "center" },

      //{ Header: "Customer", accessor: "customerName", width: "20%", align: "left" },
      { Header: "merchant Category", accessor: "merchantCategory", align: "center" },

      { Header: "Mall", accessor: "mallname", align: "center" },

      { Header: "merchant", accessor: "merchant", align: "center" },
      { Header: "Nationality", accessor: "nationality", align: "center" },
      { Header: "Gender", accessor: "gender", align: "center" },
      { Header: "Age", accessor: "age", align: "center" },
      { Header: "Transaction Type", accessor: "transactionType", width: "15%", align: "center" },

      { Header: "Receipt No.", accessor: "transactionNumber", width: "15%", align: "center" },

      {
        Header: "Transaction Net Amount",
        accessor: "transactionValue",
        width: "20%",
        align: "right",
      },
      { Header: "Discount", accessor: "Discount", width: "15%", align: "right" },

      { Header: "Discount Amount", accessor: "DiscountAmount", width: "15%", align: "right" },

      { Header: "Gross Amount", accessor: "GrossAmount", width: "15%", align: "right" },

      { Header: "Points", accessor: "points", width: "15%", align: "right" },
      { Header: "Balance", accessor: "balance", width: "5%", align: "left" },
      { Header: "createdate", accessor: "createdate", width: "5%", align: "left" },
    ],
    rows: happening
      ? happening.map((item) => ({
          customerName: (
            <Info data={extendedFun.truncateString(item.firstname + " " + item.lastname)} />
          ),
          memberId: <Info data={item.memberCode} />,
          nationality: <Info data={item.nationality} />,
          gender: <Info data={item.gender} />,
          age: <Info data={item.age} />,

          date: (
            <Info data={extendedFun.IsoDateToDDMMYY(item.dateOfTransaction).substring(0, 14)} />
          ),
          time: <Info data={extendedFun.IsoDateToDDMMYY(item.dateOfTransaction).substring(15)} />,

          month: <Info data={getMonthName(item.dateOfTransaction)} />,
          merchantCategory: <Info data={item.category} />,
          mallname: <Info data={item.mallname} />,
          merchant: <Info data={extendedFun.truncateString(item.shopName, 16)} />,
          transactionType: <Info data={item.transactionType ? item.transactionType : ""} />,

          transactionNumber: <Info data={item.transactionNumber ? item.transactionNumber : ""} />,
          transactionValue: (
            <Info data={item.transactionValue ? item.transactionValue.toFixed(2) : ""} />
          ),
          Discount: <Info data={item.isppldiscountapplied ? item.ppldiscount : ""} />,
          DiscountAmount: (
            <Info
              data={
                item.isppldiscountapplied
                  ? (
                      item.transactionValue / (1 - item.ppldiscount / 100) -
                      item.transactionValue
                    ).toFixed(2)
                  : "Not applied"
              }
            />
          ),

          GrossAmount: (
            <Info
              data={
                item.isppldiscountapplied
                  ? (item.transactionValue / (1 - item.ppldiscount / 100)).toFixed(2)
                  : item.transactionValue.toFixed(2)
              }
            />
          ),

          points: <Info data={item.points ? item.points : ""} />,
          balance: <Info data={item.pointbalance ? item.pointbalance : ""} />,

          createdate: (
            <Info
              data={
                item.createdate ? extendedFun.IsoDateToDDMMYY(item.createdate).substring(0, 14) : ""
              }
            />
          ),
        }))
      : [
          {
            customerName: <Info data={extendedFun.truncateString("")} />,
            memberId: <Info data={""} />,
            nationality: <Info data={""} />,
            gender: <Info data={""} />,
            age: <Info data={""} />,

            date: <Info data={""} />,
            merchant: <Info data={""} />,
            transactionType: <Info data={""} />,

            transactionNumber: <Info data={""} />,
            transactionValue: <Info data={""} />,
            Discount: <Info data={""} />,

            GrossAmount: <Info data={""} />,

            points: <Info data={""} />,
            balance: <Info data={""} />,
            createdate: <Info data={""} />,
          },
        ],
  };
}
