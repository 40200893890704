/// @mui material components
import Card from "@mui/material/Card";
import { Alert, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Linking } from "react";

// Authentication layout components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

import { useAuth } from "../../contexts/authContext";

import axios from "axios";

import Api from "constants/Api";
import { useState, useEffect } from "react";

function Referral() {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  const [filterOption, setFilterOption] = useState("");
  const [automatedRaffleId, setAutomatedRaffleId] = useState(null);
  const [promotions, setPromotions] = useState([]);

  const handleDownload = async () => {
    const url = `${Api.loyalty}/DrawPromotion/getRaffleEntries`;
    console.log("Download" + url);

    console.log(url);
    const transactionFilter = {
      couponid: automatedRaffleId || undefined,
    };

    try {
      console.log("transactionFilter:" + JSON.stringify(transactionFilter));
      const response = await axios.get(url, {
        params: transactionFilter,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Automated Raffle Entries.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("Download successful!");
    } catch (err) {
      console.error("There was an error in downloading the file!", err);
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        console.error("Unauthorized or Forbidden error", err);
        navigate("/authentication/sign-in");
      }
    }
  };

  const handleRedeemDownload = async () => {
    const url = `${Api.report}/RedeemProgram/getRedeemEntriesAsCsv`;

    console.log(url);
    const redeemFilter = {
      filter: filterOption,
    };

    try {
      const response = await axios.post(url, redeemFilter, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "RedeemEntriesReport.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("Redeem download successful!");
    } catch (err) {
      console.error("Error downloading redeem entries!", err);
      if (err.response && (err.response.status === 401 || err.response.status === 403)) {
        console.error("Unauthorized or Forbidden error", err);
        navigate("/authentication/sign-in");
      }
    }
  };
  // Fetch promotions with the IsActive filter
  useEffect(() => {
    const fetchPromotions = async () => {
      const url = `${Api.loyalty}/Promotion/getAll`;
      const filter = { IsActive: true };

      try {
        const response = await axios.get(url, {
          params: filter,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.data && response.data.items) {
          setPromotions(response.data.items);
        }
      } catch (err) {
        console.error("Error fetching promotions", err);
      }
    };

    fetchPromotions();
  }, [token]);

  // Handle dropdown value change
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setFilterOption(selectedValue);
    setAutomatedRaffleId(selectedValue);
  };
  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox ml={4} mt={2} mb={1}>
            <FormControl fullWidth>
              <InputLabel id="filter-label">Filter</InputLabel>
              <Select labelId="filter-label" value={filterOption} onChange={handleSelectChange}>
                {promotions.length > 0 ? (
                  promotions.map((promotion) => (
                    <MenuItem key={promotion.id} value={promotion.id}>
                      {promotion.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No Active Promotions</MenuItem>
                )}
              </Select>
            </FormControl>
          </MDBox>
          <MDBox ml={4} mt={2} mb={1}>
            <MDButton variant="gradient" color="info" onClick={handleDownload} size="small">
              Download Automated Raffle Entries
            </MDButton>
          </MDBox>
          <MDBox ml={4} mt={2} mb={1}>
            <MDButton
              variant="gradient"
              color="success"
              onClick={handleRedeemDownload}
              size="small"
            >
              Download Redeem Entries
            </MDButton>
          </MDBox>
        </Card>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Referral;
