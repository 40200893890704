/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import PropTypes from "prop-types";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images

// Material Dashboard 2 React components

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";
import MSSelect from "components/MSSelect";

import { Controller, useForm } from "react-hook-form";
import FormInputText from "form-components/FormInputText";

import * as extendedFun from "extensions/extendedFunctions";
import Api from "constants/Api";
import axios from "axios";
import MDAlert from "components/MDAlert";
import { FormControl, FormHelperText, Input, MenuItem, Select, Typography } from "@mui/material";
import { useAuth } from "../../../../contexts/authContext";

// Happening page components
//"happeningId", mallid, category, title, "subTitle", "startDate", "endDate", "showingDate", "endShowingDate",
// details, image, link, "createdBy", "createdDate", "lastModifiedBy", "lastModifiedDate"
const initMalls = [
  { id: "", name: "Select Mall*" },
  { id: 1, name: "Mercato" },
  { id: 2, name: "Towncenter" },
  { id: 3, name: "United Arab Emirates" },
];

const initHappening = [
  { id: "", name: "Select Category*" },
  { id: 1, name: "NOW OPEN" },
  { id: 2, name: "Events" },
];
const initForm = {
  happeningId: null,
  mallId: "",
  title: null,
  subTitle: null,
  startDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
  endDate: null,
  showingDate: null,
  endShowingDate: null,
  details: null,
  image: null,
};

function New({ action }) {
  const [currentDate, setCurrentDate] = useState(extendedFun.IsoDateToYYYYMMDD(new Date()));
  const [malls, setMall] = useState(initMalls);
  const [happening, setHappening] = useState();

  const [message, setMessage] = useState("");
  const [alertType, setAlertType] = useState("success");

  const [image, setImage] = useState(null);
  let { isAuthenticated, roles, merchantId, token } = useAuth();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      happeningId: "",
      mallId: "",
      title: "",
      subTitle: "",
      startDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
      endDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
      showingDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
      endShowingDate: extendedFun.IsoDateToYYYYMMDD(new Date()),
      details: "",
      image: null,
      firstName: "",
    },
  });

  const onSubmit = (data) => {
    console.log("data:" + JSON.stringify(data));
    if (!validate(data)) {
      return false;
    }

    const formData = new FormData();
    formData.append("happeningId", data.happeningId);
    formData.append("mallId", data.mallId);
    formData.append("title", data.title);
    formData.append("subTitle", data.subTitle);
    formData.append("startDate", data.startDate);
    formData.append("endDate", data.endDate);
    formData.append("showingDate", data.showingDate);
    formData.append("endShowingDate", data.endShowingDate);
    formData.append("details", data.details);
    formData.append("image", image);
    console.log("formData:" + JSON.stringify(formData));
    //formData.append("title", data.title);
    create(formData);
  };

  const validate = (data) => {
    if (data.mallId == 0) {
      return false;
    }
    return true;
  };
  const create = (data) => {
    const url = `${Api.merchant}/HappeningSub`;
    console.log("url:" + url);
    /*const config = {
      headers: {
        Authorization: "Bearer " + YOUR_TOKEN_HERE,
      },
    };*/
    console.log("Token$:" + token);
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        console.log(response.data);
        setMessage("New happening create successfully");
        setAlertType("success");
        reset();
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });
  };

  const getHappeningCategory = () => {
    const url = `${Api.merchant}/Happening/getAll`;
    console.log("url:" + url);

    axios.get(url).then((response) => {
      const arr = response.data;
      console.log("getHappeningCategory ***:" + JSON.stringify(arr.items));

      setHappening(arr.items);
    });
  };

  useEffect(() => {
    getHappeningCategory();
  }, []);
  const alertContent = (name, type) => (
    <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
      {type}:
      <MDTypography variant="body2" color="white">
        {message}
      </MDTypography>
    </MDTypography>
  );
  return (
    <>
      <MDBox
        color="white"
        p={2}
        justifyContent="center"
        alignItems="center"
        mx={0}
        sx={{
          width: "90%",
          height: "100%",
          flex: 1,
        }}
      >
        <Card>
          {/*<MDBox ml={2} mt={1} mb={1}>
            <MDButton component={Link} to={action.route} variant="text" color="info">
              back
            </MDButton>
          </MDBox>*/}
          <MDBox pt={0} pb={3} px={3}>
            <MDBox role="form">
              <form>
                {message && (
                  <MDBox>
                    <MDAlert color={alertType} dismissible>
                      {alertContent(message, alertType)}
                    </MDAlert>
                  </MDBox>
                )}
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={0} px={2}>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "title is required",
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl error={!!errors.mallId} style={{ minWidth: 120, marginTop: 16 }}>
                        <MDInput
                          required
                          label="title"
                          error={!!errors.title}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.title?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={0} px={2}>
                  <Controller
                    name="subTitle"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "subTitle is required",
                      //validate: (value) => Number(value) > 10 || "greater than 10",
                    }}
                    render={({ field }) => (
                      <FormControl error={!!errors.mallId} style={{ minWidth: 120, marginTop: 16 }}>
                        <MDInput
                          required
                          label="subTitle"
                          error={!!errors.subTitle}
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.subTitle?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={0} px={2}>
                  <Controller
                    name={"mallId"}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Mall is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.mallId} style={{ minWidth: 120, marginTop: 16 }}>
                        <Select required displayEmpty {...field}>
                          {malls.map((element, index) => {
                            return (
                              <MenuItem m={20} key={index} value={element.id}>
                                {element.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{errors.mallId?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={0} px={2}>
                  <Controller
                    name={"happeningId"}
                    control={control}
                    defaultValue=""
                    rules={{ required: "Category is required" }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.happeningId}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <Select required displayEmpty {...field}>
                          {happening &&
                            happening.map((element, index) => {
                              return (
                                <MenuItem m={20} key={index} value={element.id}>
                                  {element.title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>{errors.happeningId?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="startDate"
                    control={control}
                    defaultValue={"2002-1-1"}
                    rules={{
                      required: "Date is required",
                      //validate: (value) => Date(value) > Date(currentDate) || "in future",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.startDate}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <MDInput
                          required
                          label="Start Date"
                          error={!!errors.startDate}
                          type="date"
                          //helperText={errors.title?.message}
                          {...field}
                        />
                        <FormHelperText>{errors.startDate?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="endDate"
                    control={control}
                    defaultValue={"2002-1-1"}
                    rules={{
                      required: "Date is required",
                      //validate: (value) => Date(value) > Date(currentDate) || "in future",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.endDate}
                        style={{ minWidth: 120, marginTop: 16 }}
                        sx={{ mx: 20 }}
                      >
                        <MDInput
                          required
                          label="end Date"
                          error={!!errors.endDate}
                          type="date"
                          {...field}
                        />
                        <FormHelperText>{errors.endDate?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="showingDate"
                    control={control}
                    defaultValue={"2002-1-1"}
                    rules={{
                      required: "showing Date is required",
                      //validate: (value) => Date(value) > Date(currentDate) || "in future",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.showingDate}
                        style={{ minWidth: 120, marginTop: 16 }}
                      >
                        <MDInput
                          required
                          label="Showing Date Date"
                          error={!!errors.showingDate}
                          type="date"
                          {...field}
                        />
                        <FormHelperText>{errors.showingDate?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="endShowingDate"
                    control={control}
                    defaultValue={"2002-1-1"}
                    rules={{
                      required: "End Showing Date is required",
                      //validate: (value) => Date(value) > Date(currentDate) || "in future",
                    }}
                    render={({ field }) => (
                      <FormControl
                        error={!!errors.endShowingDate}
                        style={{ minWidth: 120, marginTop: 16 }}
                        sx={{ mx: 20 }}
                      >
                        <MDInput
                          required
                          label="end Date"
                          error={!!errors.endShowingDate}
                          type="date"
                          {...field}
                        />
                        <FormHelperText>{errors.endShowingDate?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1} px={2}>
                  <Controller
                    name="details"
                    control={control}
                    defaultValue=""
                    rules={{ required: "details is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.details} fullWidth>
                        <MDInput
                          label="details"
                          error={!!errors.details}
                          //helperText={errors.details?.message}
                          {...field}
                          multiline
                          rows="3"
                          fullWidth
                        />
                        <FormHelperText>{errors.details?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" px={2} mb={4}>
                  <Controller
                    name="image"
                    control={control}
                    defaultValue=""
                    rules={{ required: "image is required" }}
                    render={({ field }) => (
                      <FormControl error={!!errors.image} style={{ minWidth: 120, marginTop: 16 }}>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            setImage(e.target.files[0]);

                            const file = e.target.files[0];
                            field.onChange(file);
                            setValue("image", file);
                            console.log("file:" + JSON.stringify(file));
                            //console.log("file:" + JSON.stringify(file.name));
                            //console.log("file:" + JSON.stringify(e.target.files[0]));
                          }}
                        />
                        <FormHelperText>{errors.image?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </MDBox>

                {/*<MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1}  px={2}>
                                 <MSSelect
                    name={"mallId"}
                    control={control}
                    label={"Select Mall"}
                    options={malls}
                    required
                    initValue={1}
                    value={1}
                  />
                  <MSSelect
                    name={"happeningId"}
                    control={control}
                    options={happening}
                    label={"Select Category"}
                    initValue={1}
                    sx={{ px: 20 }}
                    required
                  />
                </MDBox>

                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1}  px={2}>
                  <FormInputText
                    name={"startDate"}
                    control={control}
                    label={"Start Date"}
                    required
                    type="date"
                    initValue={currentDate}
                    mx={30}
                  />
                  <FormInputText
                    name={"endDate"}
                    control={control}
                    label={"End Date"}
                    required
                    variant="standard"
                    type="date"
                    initValue={currentDate}
                    sx={{ mx: 20 }}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1}  px={2}>
                  <FormInputText
                    name={"showingDate"}
                    control={control}
                    label={"Showing Date"}
                    required
                    variant="standard"
                    type="date"
                    initValue={currentDate}
                  />
                  <FormInputText
                    name={"endShowingDate"}
                    control={control}
                    label={"End Showing Date"}
                    required
                    variant="standard"
                    type="date"
                    initValue={currentDate}
                    sx={{ mx: 20 }}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1}  px={2}>
                  <Controller
                    name="details"
                    control={control}
                    defaultValue=""
                    rules={{ required: "details is required" }}
                    render={({ field }) => (
                      <MDInput
                        label="details"
                        error={!!errors.details}
                        helperText={errors.details?.message}
                        {...field}
                        multiline
                        rows="3"
                        fullWidth
                      />
                    )}
                  />
                </MDBox>
                <MDBox display="flex" justifyContent="flex-start" alignItems="center" pt={1}  px={2}>
                  <MDTypography px={2} variant="button" fontWeight="medium">
                    Upload Image
                  </MDTypography>
                </MDBox>*/}
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </form>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
}

New.propTypes = {
  action: PropTypes.shape({
    //type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
  }),
};

export default New;
