//import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//import { saveAs } from "file-saver";

// Material Dashboard 2 React components
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { useEffect, useState, useRef, useCallback } from "react";

import DataTable from "examples/Tables/DataTable";
import authorsTableData from "./data/authorsTableData";
import ExcelJS from "exceljs";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import { useParams } from "react-router-dom";
import { MenuItem, Select, Button, InputLabel, FormControl, TextField } from "@mui/material";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import * as extendedFun from "extensions/extendedFunctions";

import { useNavigate } from "react-router-dom";

import "./styles.css"; // Import your CSS file

const nationalities = [{ id: "all", name: "All nationalities" }];
const genders = [{ id: "all", name: "All genders" }];

import { useAuth } from "../../contexts/authContext";
import Api from "constants/Api";
import axios from "axios";

import { debounce } from "lodash";

const imageUrl1 = "../pplLogo.jpg";
const imageUrl2 = "../Logo-english.jpg";
const imageUrl3 = "../tc_logo.jpg";

// Function to return array of objects with unique elements
function getUniqueObjects(array) {
  // Create a Set to store unique IDs
  let uniqueIds = new Set();

  // Use filter to iterate through the array and keep only the unique objects
  let uniqueArray = array.filter((obj) => {
    if (uniqueIds.has(obj.id)) {
      return false; // If ID already exists, filter it out
    } else {
      uniqueIds.add(obj.id); // Add ID to Set
      return true; // Keep the object
    }
  });

  return uniqueArray;
}

function memberdetails() {
  let { isAuthenticated, roles, merchantId, token } = useAuth();
  //const [list, setList] = useState(true);
  //const [newEntry, setNewEntry] = useState(false);
  //const [editEntry, setEditEntry] = useState(false);
  const [id, setId] = useState(null);
  const [merchants, setMerchants] = useState({ items: [] });
  const [merchantName, setMerchantName] = useState(null);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedTransactionType, setSelectedTransactionType] = useState();
  const [selectedNationality, setSelectedNationality] = useState();
  const [selectedGender, setSelectedGender] = useState();
  const [searchTerm, setSearchTerm] = useState();
  const [open, setOpen] = useState(false);
  const [editedId, setEditedId] = useState(null);
  const [editedEmail, setEditedEmail] = useState(null);
  const [editedMobile, setEditedMobile] = useState(null);

  const [nationalityList, setnationalityList] = useState(nationalities);
  const [genderList, setGenderList] = useState(genders);

  const [transactionFilter, setTransactionFilter] = useState({ merchantId: merchantId });
  const [submittedTransactionFilter, setSubmittedTransactionFilter] = useState({});
  const [transactions, setTransactions] = useState([]);

  const [memberCode, setMemberCode] = useState();
  const [email, setEmail] = useState("");
  const [email1, setEmail1] = useState("");

  // Debounced function to handle email input changes
  const debouncedSetEmail1 = useCallback(
    debounce((value) => {
      setEmail1(value);
    }, 300), // Adjust the debounce delay as needed
    []
  );

  const handleEmailChange = (e) => {
    debouncedSetEmail1(e.target.value);
  };
  // Memoize the setEmail function to prevent unnecessary re-renders
  /*onst handleEmailChange = (e) => {
    setTransactionFilter((prevState) => ({ ...prevState, email: e.target.value }));
    setEmail(e.target.value);
  };*/

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, email: email1 }));
  }, [email1]);

  const [mobileNumber, setMobileNumber] = useState();
  const params = useParams();
  const { columns, rows } = authorsTableData(
    transactions,
    setOpen,
    setEditedId,
    setEditedEmail,
    setEditedMobile
  );

  const handleClose = () => {
    setOpen(false);
  };
  const UpdateUser = async () => {
    const url = `${Api.IdentityServer1}/Member/UpdateUser`;
    const data = {
      originalEmail: editedId,
      newEmail: editedEmail,
      newMobile: editedMobile,
    };
    console.log("ccc:" + JSON.stringify(data));
    console.log("ccc:" + JSON.stringify(url));
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        setOpen(false);
        alert("UpdateUser:" + arr);
      })
      .catch((err) => {
        alert("UpdateUser:" + err);

        console.error("There was an error in getMerchant!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate("/authentication/sign-in");
        }
      });
  };
  const UpdateMember = async () => {
    const url = `${Api.apiLoyalty}/Member/UpdateUser`;
    const data = {
      originalEmail: editedId,
      newEmail: editedEmail,
      newMobile: editedMobile,
    };
    axios
      .post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        setOpen(false);

        alert("UpdateMember:" + arr);
      })
      .catch((err) => {
        console.error("There was an error in getMerchant!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate("/authentication/sign-in");
        }
      });
  };
  const confirmUpdate = (data) => {
    UpdateUser();
    UpdateMember();
    /* const url = `${Api.merchant}/merchant/delete?id=${editedId}`;
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    axiosMerchant
      .delete(url)
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        console.log(response.data);
        setMessage("Merchant deleted successfully");
        setAlertType("success");
        setRefresh((prevCheck) => !prevCheck);
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setMessage(JSON.stringify(error.message));
        setAlertType("error");
        setTimeout(function () {
          setMessage(null);
        }, 5000);
      });*/
  };

  /*useEffect(() => {
    console.log("Email:" + email);
    setTransactionFilter((prevState) => ({ ...prevState, email: email }));
  }, [email]);*/
  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, memberCode: memberCode }));
  }, [memberCode]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, mobile: mobileNumber }));
  }, [mobileNumber]);

  const navigate = useNavigate();

  const handleDateChange = (date) => {
    var dateF = new Date(date);

    setSelectedDate(dateF);
  };
  const handleEndDateChange = (date) => {
    var dateF = new Date(date);

    setSelectedEndDate(dateF);
  };

  const handleChangeTransactionType = (event) => {
    const selectedValue = event.target.value;

    setSelectedTransactionType(selectedValue);
  };

  const handleChangeNationality = (event) => {
    const selectedValue = event.target.value;

    setSelectedNationality(selectedValue);
  };

  const handleChangeGender = (event) => {
    const selectedValue = event.target.value;

    setSelectedGender(selectedValue);
  };

  /**useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, merchantId: merchantId }));
  }, [merchantId]);*/
  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, startDate: selectedDate }));
  }, [selectedDate]);

  useEffect(() => {
    setTransactionFilter((prevState) => ({ ...prevState, endDate: selectedEndDate }));
  }, [selectedEndDate]);

  useEffect(() => {
    if (selectedTransactionType !== "all") {
      setTransactionFilter((prevState) => ({
        ...prevState,
        transactionType: selectedTransactionType,
      }));
    } else {
      setTransactionFilter((prevState) => ({
        ...prevState,
        transactionType: "",
      }));
    }
  }, [selectedTransactionType]);

  useEffect(() => {
    if (selectedNationality !== "ALL") {
      setTransactionFilter((prevState) => ({
        ...prevState,
        nationality: selectedNationality,
      }));
    } else {
      setTransactionFilter((prevState) => ({
        ...prevState,
        nationality: "",
      }));
    }
  }, [selectedNationality]);

  useEffect(() => {
    if (selectedGender !== "all") {
      setTransactionFilter((prevState) => ({
        ...prevState,
        gender: selectedGender,
      }));
    } else {
      setTransactionFilter((prevState) => ({
        ...prevState,
        gender: "",
      }));
    }
  }, [selectedGender]);
  console.log("Token^:" + token);
  //console.log("access TransactionReport");
  //  const { columns: pColumns, rows: pRows } = projectsTableData();
  const getMerchant = async () => {
    const url = `${Api.merchant}/Merchant/getAllShortData?${merchantId}`;
    console.log("Token****:" + token);

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        //console.log("array:" + JSON.stringify(arr));
        setMerchants(arr);
      })
      .catch((err) => {
        console.error("There was an error in getMerchant!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    getMerchant();
  }, []);
  useEffect(() => {
    let merchant = merchants.items.find((a) => a.id == merchantId);
    let merchantName = merchant ? merchant.name : null;

    setMerchantName(merchantName);
  }, [merchants, merchantId]);
  //get data from server

  const getTransaction = () => {
    //change api endpoin
    const url = `${Api.report}/Member/Details`;

    axios
      .post(url, submittedTransactionFilter, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Authorization header with your token
        },
      })
      .then((response) => {
        const arr = response.data;
        console.error("response!#", JSON.stringify(response));

        setTransactions(arr);
      })
      .catch((err) => {
        console.error("There was an error in getTransaction!#", err);
        if (err.response && (err.response.status === 401 || err.response.status === 403)) {
          navigate("/authentication/sign-in");
        }
      });
  };

  useEffect(() => {
    const elements = nationalities;
    if (transactions && Array.isArray(transactions) && transactions.length > 0) {
      for (let i = 0; i < transactions.length; i++) {
        elements.push({ id: transactions[i].nationality, name: transactions[i].nationality });
      }
    }

    // Call the function and store the result
    let uniqueObjectsArray = getUniqueObjects(elements);

    setnationalityList(extendedFun.uppercasePropertyNames(uniqueObjectsArray));
  }, [transactions]);

  useEffect(() => {
    const elements = genders;
    if (transactions && Array.isArray(transactions) && transactions.length > 0) {
      for (let i = 0; i < transactions.length; i++) {
        elements.push({ id: transactions[i].gender, name: transactions[i].gender });
      }
    }

    // Call the function and store the result
    let uniqueObjectsArray = getUniqueObjects(elements);

    setGenderList(uniqueObjectsArray);
  }, [transactions]);

  useEffect(() => {
    getTransaction();
  }, [submittedTransactionFilter]);

  //end get data from server
  const tableRef = useRef(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Function to load and draw an image
  const loadAndDrawImage = async (doc, url, x, y, width, height) => {
    return new Promise((resolve) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function () {
        let reader = new FileReader();
        reader.readAsDataURL(xhr.response);
        reader.onloadend = function () {
          doc.addImage(reader.result, "JPEG", x, y, width, height);
          resolve();
        };
      };
      xhr.send();
    });
  };

  // Function to calculate average spending

  const calculateTotalPrivilegePLUSTransactions = () => {
    const totalSpending = transactions.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending;
    return averageSpending.toFixed(2);
  };
  // Function to calculate average spending
  const calculateAverageSpending = () => {
    const totalSpending = transactions.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending / transactions.length;
    return averageSpending.toFixed(2);
  };

  const calculateAverageSpendingPurchaseExchange = () => {
    const totalSpending = transactions.reduce((acc, curr) => {
      return acc + curr.transactionValue;
    }, 0);
    const averageSpending = totalSpending / transactions.length;
    return averageSpending.toFixed(2);
  };

  // Function to calculate the maximum spending
  const calcHighestTransactionValue = () => {
    const maxSpending = transactions.reduce((max, curr) => {
      return Math.max(max, curr.transactionValue);
    }, 0);

    return maxSpending.toFixed(2);
  };

  // Function to calculate the maximum spending
  /*const calcLowestTransactionValue = () => {
    const minSpending = transactions.reduce((min, curr) => {
      return Math.min(min, curr.transactionValue);
    }, transactions[0].transactionValue);

    return minSpending.toFixed(2);
  };*/
  const calcLowestTransactionValue = () => {
    // Filter transactions to only include positive values
    const positiveTransactions = transactions.filter(
      (transaction) => transaction.transactionValue > 0
    );

    if (positiveTransactions.length === 0) {
      return 0; // Return 0 if there are no positive transactions. You can adjust this default value if needed.
    }

    // Find the lowest positive transaction value
    const minSpending = positiveTransactions.reduce((min, curr) => {
      return Math.min(min, curr.transactionValue);
    }, positiveTransactions[0].transactionValue);

    return minSpending.toFixed(2);
  };

  // Function to calculate average spending
  const calculatePointsGainedForTheTenant = () => {
    const totalPoints = transactions.reduce((acc, curr) => {
      return acc + curr.points;
    }, 0);
    return totalPoints.toFixed(2);
  };

  useEffect(() => {
    /*const result = calculateTransactionDetails();

    setTotalTrans(result.totalPrivilegePLUSTransactions);
    setTotalSalesExchangeTrans(result.TotalSalesExchangeTrans);
    setAmountSalesExchange(result.AmountSalesExchange);
    setTotalAmountSales(result.totalSaleValue);
    setTotalVoidAmt(result.totalVoidAmount);
    setTotalVoid(result.totalVoidTransactions);
    setAverageSpending(calculateAverageSpending());
    setHighestTransactionValue(calcHighestTransactionValue());
    setLowestTransactionValue(calcLowestTransactionValue());
    setPointsGainedForTheTenant(calculatePointsGainedForTheTenant());*/
  }, [transactions]);

  function calculateTransactionDetails() {
    let totalVoidAmount = 0;
    let totalNumberOfVoidTransactions = 0;
    let totalNumberOfTransactions = 0;

    //total of every thing
    let totalSalesAmount = 0;
    //////////////
    //////Purchase,Exchange,Void-Refund
    let totalSalesExchangeTrans = 0;
    let amountSalesExchange = 0;
    ////////

    for (let transaction of transactions) {
      if (
        transaction.transactionType === "Void-Purchase" ||
        transaction.transactionType === "Void-Exchange" ||
        transaction.transactionType === "Void-Refund"
      ) {
        totalVoidAmount += transaction.transactionValue;
        totalNumberOfVoidTransactions++;
      }
      totalSalesAmount += transaction.transactionValue;
      totalNumberOfTransactions++;

      if (
        transaction.transactionType === "Purchase" ||
        transaction.transactionType === "Exchange"
      ) {
        totalSalesExchangeTrans++;
      }
      if (
        transaction.transactionType === "Void-Purchase" ||
        transaction.transactionType === "Void-Exchange"
      ) {
        totalSalesExchangeTrans--;
      }
      if (
        transaction.transactionType === "Purchase" ||
        transaction.transactionType === "Exchange" ||
        transaction.transactionType === "Void-Refund"
      ) {
        //totalSalesExchangeTrans++;
        amountSalesExchange += transaction.transactionValue;
      }

      if (
        transaction.transactionType === "Void-Purchase" ||
        transaction.transactionType === "Void-Exchange" ||
        transaction.transactionType === "Refund"
      ) {
        //totalSalesExchangeTrans++;
        amountSalesExchange += transaction.transactionValue;
      }
    }

    // Calculate net sales
    //let totalNetSales = totalSalesAmount + totalRefundAmount; // Refunds are usually negative values, so adding them will subtract from the sales.

    return {
      totalVoidAmount: totalVoidAmount,
      totalVoidTransactions: totalNumberOfVoidTransactions,
      totalPrivilegePLUSTransactions: totalNumberOfTransactions,
      totalSaleValue: totalSalesAmount,
      TotalSalesExchangeTrans: totalSalesExchangeTrans,
      AmountSalesExchange: amountSalesExchange,
    };
  }

  function formatAsAccounting(value) {
    return value;
    /*return value.toLocalString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });*/
  }

  function getDateSection(dateTimeStr) {
    const [datePart] = dateTimeStr.trim().split(" ");
    return datePart;
  }
  function getTimeSection(dateTimeStr) {
    const [, timePart] = dateTimeStr.trim().split(" ");
    return timePart;
  }
  const Download = () => {
    switch (downloadFormat.toLowerCase()) {
      case "pdf":
        generateXLSX();
        break;
      case "xlsx":
        generateXLSX();
        break;
      case "xls":
        generateXLSX();
        break;
      case "csv":
        generateXLSX();
        break;
    }
  };
  const generateXLSX = async () => {
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Members Details");

      // Load and draw images
      /*const imageUrl1 = "../pplLogo.jpg";
      const imageUrl2 = "../Logo-english.jpg";
      const imageUrl3 = "../tc_logo.jpg";

      const image1 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl1),
        extension: "jpeg",
      });

      const image2 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl2),
        extension: "jpeg",
      });

      const image3 = workbook.addImage({
        base64: await getBase64ImageFromUrl(imageUrl3),
        extension: "jpeg",
      });*/

      //worksheet.addImage(image1, "C2:D3");
      //worksheet.addImage(image2, "A2:B3");
      //worksheet.addImage(image3, "E2:F3");

      // Adjust columns and rows to fit image dimensions
      worksheet.getColumn(3).width = 15; // Adjust width of column C
      worksheet.getColumn(4).width = 15; // Adjust width of column D
      //worksheet.getRow(2).height = 60; // Adjust height of row 2
      // worksheet.getRow(3).height = 60; // Adjust height of row 3

      /*worksheet.addImage(image1, {
        tl: { col: 2, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image2, {
        tl: { col: 0, row: 1 },
        ext: { width: 200, height: 60 },
      });

      worksheet.addImage(image3, {
        tl: { col: 4.9, row: 1 },
        ext: { width: 200, height: 60 },
      });*/
      // Title & Date range
      /*worksheet.mergeCells("A5:F5");
      const titleCell = worksheet.getCell("A5");
      titleCell.value = "Member Details";
      titleCell.alignment = { horizontal: "center" };*/

      if (selectedDate) {
        const dateText = selectedEndDate
          ? `Start Date: ${formatDate(selectedDate)} - End Date: ${formatDate(selectedEndDate)}`
          : `Start Date: ${formatDate(selectedDate)}`;

        worksheet.mergeCells("A6:F6");
        const dateCell = worksheet.getCell("A6");
        dateCell.value = dateText;
        dateCell.alignment = { horizontal: "center" };
      }

      //worksheet.mergeCells("A7:F7");

      // Table Headers (Below Title, Date, and Merchant Name)
      worksheet.addRow(); // Empty row for spacing

      const headerRow = worksheet.addRow([
        "memberId",
        "first Name",
        "last Name",
        "Mobile Number.",
        "Email",
        "Nationality",
        "age",
        "Gender",
        "Member Type",
        "Is Existing",
        "Completed login",
        "Date of joining",
        "Time",
      ]);

      headerRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "AB8E66" },
        };
      });

      const data = transactions
        .filter((transaction) => !transaction.firstName.toLowerCase().includes("user"))
        .map((transaction) => {
          return {
            code: transaction.code,

            firstName: transaction.firstName,
            lastName: transaction.lastName,
            phoneNumber: transaction.phoneNumber,
            Email: transaction.email,
            nationality: transaction.nationality,
            age: transaction.age,
            gender: transaction.gender,
            residenceStatus: transaction.residenceStatus,
            isExistingMember: transaction.isExistingMember,
            isCompletedLogin: transaction.isCompletedLogin,
            created_on: getDateSection(extendedFun.IsoDateToDDMMYY(transaction.created_on)),
            time: getTimeSection(extendedFun.IsoDateToDDMMYY(transaction.created_on)),
          };
        });
      //worksheet.addRows(data);
      data.forEach((transaction) => {
        worksheet.addRow([
          transaction.code,
          transaction.firstName,
          transaction.lastName,
          transaction.phoneNumber,
          transaction.Email,
          transaction.nationality,
          transaction.age,
          transaction.gender,
          transaction.residenceStatus,
          transaction.isExistingMember,
          transaction.isExistingMember == true ? transaction.isCompletedLogin : " ",
          transaction.created_on,
          transaction.time,
        ]);
      });

      // Autosize columns
      worksheet.columns = [
        { key: "code", width: 15 },
        { key: "firstName", width: 15 },
        { key: "lastName", width: 24 },
        { key: "phoneNumber", width: 24 },
        { key: "email", width: 15 },
        { key: "nationality", width: 20 },
        { key: "gender", width: 15 },
        { key: "residenceStatus", width: 15 },
        { key: "isExistingMember", width: 20 },
        { key: "Dateofjoining", width: 30 },
        { key: "time", width: 20 },
      ];
      /*worksheet.getColumn("date").alignment = { horizontal: "center" };
      worksheet.getColumn("id").alignment = { horizontal: "center" };
      worksheet.getColumn("nationality").alignment = { horizontal: "center" };

      worksheet.getColumn("receipt").alignment = { horizontal: "center" };

      worksheet.getColumn("amount").alignment = { horizontal: "right" };

      worksheet.getColumn("points").alignment = { horizontal: "right" };
      worksheet.getColumn("type").alignment = { horizontal: "center" };*/

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: 14, // You can adjust this value to your desired font size
          };
        });
      });

      const desiredFontSize = 14;

      // Adjust font size for the entire worksheet
      worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.height = 14 * 1.5; // Adjusting row height
        row.eachCell(function (cell, colNumber) {
          cell.font = {
            size: desiredFontSize,
          };
        });
      });

      // Optionally, adjust font size for specific rows, such as headers
      const titleRow = worksheet.getRow(5);
      titleRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const dateRow = worksheet.getRow(6);
      dateRow.eachCell(function (cell) {
        cell.font = {
          size: 16, // Choose whatever font size you want for the title
          //bold: true, // Optional, if you want the title to be bold
        };
      });
      const headerRow2 = worksheet.getRow(7);
      headerRow2.eachCell(function (cell, colNumber) {
        cell.font = {
          size: 16, // Adjusting font size for the header row
        };
      });
      /*
      //Add summary
      // Calculate the average spending
      // const totalTransaction = arrayOfObjects.reduce((acc, curr) => acc + (curr.transactionValue || 0), 0);
      const result = calculateTransactionDetails();

      const totalPrivilegePLUSTransactions = result.totalPrivilegePLUSTransactions;
      //const totalSaleValue = result.totalSaleValue;
      const totalSalesTransaction = result.TotalSalesExchangeTrans;
      const totalSaleValue = result.AmountSalesExchange;

      //const totalSalesTransactions = result.totalSalesExchangeTrans;
      const totalVoidAmount = result.totalVoidAmount;
      const totalVoidTransactions = result.totalVoidTransactions;

      const averageSpending = calculateAverageSpending();
      const highestTransactionValue = calcHighestTransactionValue();
      const lowestTransactionValue = calcLowestTransactionValue();

      const pointsGainedForTheTenant = calculatePointsGainedForTheTenant();

      let currentRow = worksheet.rowCount + 5; // Get the next row after your data
      // Now, add the summary rows
      // Title & Date range
      /*worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
      worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
      worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
      worksheet.getCell(`A${currentRow}`).value = "Description:";
      worksheet.getCell(`C${currentRow}`).value = "Values";*/
      /*
            // Assuming currentRow is already defined
            const rangeA = `A${currentRow}`;
            const rangeB = `B${currentRow}`;
            const rangeC = `C${currentRow}`;
      
            worksheet.mergeCells(rangeA, rangeB);
            worksheet.getCell(rangeA).alignment = { horizontal: "left" };
            worksheet.getCell(rangeC).alignment = { horizontal: "right" };
            worksheet.getCell(rangeA).value = "Description";
            worksheet.getCell(rangeC).value = "Values";
      
            // Apply borders
            const borderStyle = { style: "thin" };
      
            worksheet.getCell(rangeA).border = {
              top: borderStyle,
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            };
            worksheet.getCell(rangeB).border = {
              top: borderStyle,
              bottom: borderStyle,
              right: borderStyle, // This cell is merged with the previous one so no need for the left border.
            };
            worksheet.getCell(rangeC).border = {
              top: borderStyle,
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
            };
      
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
            worksheet.getCell(`C${currentRow}`).value = totalPrivilegePLUSTransactions.toString();
            currentRow++;
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Transactions:";
            worksheet.getCell(`C${currentRow}`).value = totalSalesTransaction.toString();
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Total Sales Amount:";
            worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
              totalSaleValue
            ).toString()}`;
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Total Void Transactions:";
            worksheet.getCell(`C${currentRow}`).value = totalVoidTransactions.toString();
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Total Void Amount:";
            worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
              -1 * totalVoidAmount
            ).toString()}`;
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Total PrivilegePLUS Points Rewarded:";
            worksheet.getCell(`C${currentRow}`).value =
              formatAsAccounting(pointsGainedForTheTenant).toString();
            currentRow++;
            currentRow++;
            worksheet.getCell(`A${currentRow}`).value = "Summary:";
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Highest Transaction Value:";
            worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
              highestTransactionValue
            ).toString()}`;
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            worksheet.getCell(`A${currentRow}`).value = "Lowest Transaction Value:";
            worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
              lowestTransactionValue
            ).toString()}`;
            currentRow++;
      
            worksheet.mergeCells(`A${currentRow}:B${currentRow}`);
            worksheet.getCell(`A${currentRow}`).alignment = { horizontal: "left" };
            worksheet.getCell(`C${currentRow}`).alignment = { horizontal: "right" };
            /*worksheet.getCell(`A${currentRow}`).value = "Average Transaction Value:";
            worksheet.getCell(`C${currentRow}`).value = `AED ${formatAsAccounting(
              averageSpending
            ).toString()}`;*/
      //currentRow++;

      // Adjust font size and styles if needed (optional)
      for (let i = worksheet.rowCount; i >= worksheet.rowCount - 20; i--) {
        let row = worksheet.getRow(i);
        row.eachCell((cell) => {
          cell.font = {
            size: 14,
          };
        });
      }

      // ... continue with any other operations, such as saving the workbook.

      // Download the Excel
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Member Details.xlsx";
      link.click();
    } catch (error) {}
  };

  // Utility function to get base64 encoded image from URL
  const getBase64ImageFromUrl = async (imageUrl) => {
    const res = await fetch(imageUrl);
    const blob = await res.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });
  };

  const [downloadFormat, setDownloadFormat] = useState("pdf");

  return (
    <DashboardLayout>
      <MDBox mt={8}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            //bgColor="#AB8E66"
            //borderRadius="lg"
            //coloredShadow="success"
            mx={2}
            mt={-3}
            p={2}
            mb={0}
            textAlign="center"
          >
            <MDTypography variant="h4" color="white">
              Member Details {merchantName && merchantName}
            </MDTypography>
          </MDBox>

          <MDBox display="flex" alignItems="center" justifyContent="space-between" mx={1}>
            {/*<filters*/}
            <MDBox display="flex" alignItems="center">
              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    onChange={(newValue) => handleDateChange(newValue)}
                    sx={{
                      "& .MuiFormLabel-root.MuiInputLabel-root": {
                        marginTop: "0px", // Adjust the value as per your requirements
                      },
                      "& .MuiInputBase-root": {
                        height: "46px",
                        width: "160px",
                      },
                      "& .MuiOutlinedInput-root": {
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "10px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "10px",
                        borderWidth: "0px",
                      },
                    }}
                  />
                </LocalizationProvider>
                <MDBox ml={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      onChange={(newValue) => handleEndDateChange(newValue)}
                      sx={{
                        "& .MuiFormLabel-root.MuiInputLabel-root": {
                          marginTop: "0px", // Adjust the value as per your requirements
                        },
                        "& .MuiInputBase-root": {
                          height: "46px",
                          width: "160px",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",
                          margin: "0px",
                          paddingRight: "10px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "10px",
                          borderWidth: "0px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </MDBox>
              </MDBox>
              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                <FormControl
                  style={{
                    marginLeft: 10,
                    height: "55px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel
                    htmlFor="nationality-select"
                    style={{
                      marginLeft: 10,
                      height: "46px",
                    }}
                  >
                    Nationality
                  </InputLabel>
                  <Select
                    id="nationality-select"
                    label="Nationality"
                    defaultValue={"ALL"}
                    onChange={handleChangeNationality}
                    style={{ width: "160px", height: "46px" }}
                    //InputLabelProps={{ shrink: true }}
                    //InputProps={{ disableUnderline: true }} // Disable the underline here
                  >
                    {nationalityList.map((element, index) => (
                      <MenuItem key={index} value={element.id}>
                        {element.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>

              <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={2} ml={4}>
                {genderList && (
                  <FormControl
                    style={{
                      marginLeft: 10,
                      height: "55px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <InputLabel
                      htmlFor="Gender"
                      style={{
                        marginLeft: 10,
                        height: "46px",
                      }}
                    >
                      Gender
                    </InputLabel>
                    <Select
                      id="Gender"
                      label="Gender"
                      defaultValue={"all"}
                      onChange={handleChangeGender}
                      style={{ width: "160px", height: "46px" }}
                      //InputLabelProps={{ shrink: true }}
                      //InputProps={{ disableUnderline: true }} // Disable the underline here
                    >
                      {genderList.map((element, index) => (
                        <MenuItem key={index} value={element.id}>
                          {element.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </MDBox>

              {/*<MDBox display="flex" alignItems="center" justifyContent="row" mt={2} ml={4}>
                <label class="label-small">{"Export to:"}</label>

                <MDButton onClick={generatePDF} style={{ width: "10px" }}>
                  PDF
                </MDButton>
                <MDButton onClick={exportToExcel} style={{ width: "10px" }}>
                  XLSX
                </MDButton>
                <MDButton onClick={exportToXLS} style={{ width: "10px" }}>
                  XLS
                </MDButton>
                <MDButton onClick={exportToCSV} style={{ width: "10px" }}>
                  CSV
                </MDButton>
              </MDBox>*/}
            </MDBox>

            {/*<filters*/}
          </MDBox>
          <MDBox
            display="flex"
            alignItems="center"
            justifyContent="row"
            mt={2}
            ml={3}
            style={{ height: "44px", marginLeft: 30 }}
          >
            <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={0} ml={2}>
              <TextField
                id="customer-email"
                style={{
                  width: "160px",
                  height: "46px",
                }}
                type="text"
                label="Email"
                defaultValue={email1}
                onChange={handleEmailChange}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={0} ml={2}>
              <FormControl
                style={{
                  marginLeft: 0,
                  height: "55px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MDInput
                  id="mobile-number"
                  style={{ width: "160px", height: "46px" }}
                  type="text"
                  label="Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </FormControl>
            </MDBox>

            <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={0} ml={2}>
              <FormControl
                style={{
                  marginLeft: 0,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MDInput
                  id="customer-code"
                  style={{
                    width: "160px",
                    height: "46px",
                  }}
                  type="text"
                  label="Customer Code"
                  value={memberCode}
                  onChange={(e) => setMemberCode(e.target.value)}
                />
              </FormControl>
            </MDBox>

            <MDBox display="flex" alignItems="center" justifyContent="space-around" mt={0} ml={2}>
              <MDButton
                variant="gradient"
                color="info"
                //bgColor="#AB8E66"
                //borderRadius="lg"
                //coloredShadow="success"
                onClick={() => {
                  setSubmittedTransactionFilter(transactionFilter);
                }}
                style={{ width: "90px", height: "44px", marginLeft: 10 }} // Set the width you desire
              >
                Search
              </MDButton>
            </MDBox>
            <label
              className="label-small"
              ml={4}
              style={{
                fontSize: "14px", // Bigger font size
                //padding: "8px 12px", // Padding to increase height and width
                marginLeft: 18,
              }}
            >
              {"Export to:"}
            </label>
            <select
              style={{
                fontSize: "14px", // Bigger font size
                //padding: "8px 12px", // Padding to increase height and width
                border: "1px solid #ccc", // A subtle border (optional)
                //borderRadius: "4px", // Rounded corners (optional)
                marginLeft: 4,
                height: "40px",
              }}
              onChange={(event) => {
                let selectedValue = event.target.value;

                setDownloadFormat(selectedValue);
              }}
            >
              {/*<option value="pdf">PDF</option>*/}
              <option value="xlsx">XLSX</option>
              {/*<option value="xls">XLS</option>*/}
              {/*<option value="csv">CSV</option>*/}
            </select>
            <MDButton
              variant="gradient"
              color="info"
              //bgColor="#AB8E66"
              //borderRadius="lg"
              //coloredShadow="success"
              onClick={Download}
              style={{ width: "4px", height: "44px", marginLeft: 10 }} // Set the width you desire
            >
              <DownloadIcon style={{ fontSize: "medium", width: "24", height: "24" }} />
            </MDButton>
          </MDBox>
          {/*<DataTable*/}
          <MDBox mx={4} mt={1}>
            <DataTable
              table={{ columns, rows }}
              isSorted={false}
              entriesPerPage
              showTotalEntries
              noEndBorder
              align={"middle"}
              //canSearch
            />
          </MDBox>
        </Card>
      </MDBox>
      {/*<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Winner"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={confirmUpdate} autoFocus>
            Save
          </Button>
        </DialogActions>
            </Dialog>*/}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Winner"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter the email and phone number of the winner you want to delete.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={editedEmail}
            onChange={(e) => setEditedEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="phone-number"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="outlined"
            value={editedMobile}
            onChange={(e) => setEditedMobile(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmUpdate} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Footer />*/}
    </DashboardLayout>
  );
}

export default memberdetails;
